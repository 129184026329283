import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["requestedAmount", "requirements", "submitButton"]

  connect() {
    this.loadRequestedAmount()
    this.getRequiredRequirements()
  }

  onInputChange() {
    this.getRequiredRequirements()
    this.storeRequestedAmount()
  }

  onModalOpen() {
    this.getRequiredRequirements()
  }

  getRequiredRequirements() {
    const requestedAmountValue = this.requestedAmountTarget.value
    const companyId = this.requestedAmountTarget.dataset.companyId
    const required_requirements_url = [
      "/admin/client/requirements/required?",
      `requested_amount=${requestedAmountValue}&`,
      `company_id=${companyId}`,
    ].join("")

    fetch(required_requirements_url, {
      credentials: "include",
    })
      .then((response) => {
        console.log("Server Response:", response) // Log the raw response object
        return response.json() // Parse the response as JSON
      })
      .then((requirements) => {
        console.log("Parsed Requirements:", requirements) // Log the parsed JSON
        this.processRequirements(requirements)
      })
      .catch((error) => console.error("Error:", error))
  }

  processRequirements(requirements) {
    this.formatMoney(this.requestedAmountTarget)
    this.displayRequirements(requirements, this.requirementsTarget)
    this.toggleSubmitButton(requirements, this.submitButtonTarget)
  }

  displayRequirements(requirements, requirementsTarget) {
    let content = "<p>Requirements</p>"
    content += requirements.map(this.toRequirementRow).join("")
    this.requirementsTarget.innerHTML = content
  }

  toRequirementRow(requirement) {
    return `
      <div class="description-alert ${
        requirement.completed ? "alert-success" : "alert-danger"
      }">
        <p>${requirement.text}</p>
      </div>
    `
  }

  formatMoney(requestedAmountTarget) {
    requestedAmountTarget.value = requestedAmountTarget.value
      .replace(/[^0-9]/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  toggleSubmitButton(requirements, submitButtonTarget) {
    submitButtonTarget.disabled = !requirements
      .filter((requirement) => requirement.required === true)
      .every((requirement) => requirement.completed === true)
  }

  loadRequestedAmount() {
    const storedValue = localStorage.getItem(this.localStorageIdentifier())
    if (storedValue) {
      this.requestedAmountTarget.value = storedValue
    }
  }

  storeRequestedAmount() {
    localStorage.setItem(
      this.localStorageIdentifier(),
      this.requestedAmountTarget.value
    )
  }

  localStorageIdentifier() {
    return [
      "requestedAmount",
      this.requestedAmountTarget.dataset.companyId,
    ].join("_")
  }
}
