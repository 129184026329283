import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "terms",
    "submit",
    "country",
    "currencySymbol",
    "dialCode",
    "phoneNumber",
  ]
  static values = {
    countries: Array,
  }

  connect() {
    this.onCountryChange()
    this.updateSubmitState()
  }

  onCountryChange() {
    let country = this.countriesValue.find(
      (c) => c.code == this.countryTarget.value
    )
    this.updateCurrencySymbol(country.currency)
    this.updateDialCode(country.country_code)
  }

  updateSubmitState() {
    if (this.termsTarget.checked) {
      this.submitTarget.disabled = false
      this.submitTarget.classList.remove("disabled")
    } else {
      this.submitTarget.disabled = true
      this.submitTarget.classList.add("disabled")
    }
  }

  updateCurrencySymbol(value) {
    this.currencySymbolTarget.innerHTML = value
  }

  updateDialCode(countryCode) {
    if (this.phoneNumberTarget.value.trim().length == 0) {
      this.dialCodeTarget.value = "+" + countryCode
    }
  }
}
