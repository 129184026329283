import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["customButton", "input", "list", "uploadButton"]

  connect() {
    this.selectedFiles = []
    this.customButtonTarget.addEventListener("click", () => {
      this.inputTarget.click()
    })
    this.toggleUploadButton()
  }

  preview() {
    this.selectedFiles = Array.from(this.inputTarget.files)
    this.renderFileList()
    this.toggleUploadButton()
  }

  handleDragOver(event) {
    event.preventDefault()
    event.currentTarget.classList.add("drag-over")
  }

  handleDragLeave(event) {
    event.currentTarget.classList.remove("drag-over")
  }

  handleDrop(event) {
    event.preventDefault()
    event.currentTarget.classList.remove("drag-over")

    const droppedFiles = Array.from(event.dataTransfer.files)
    this.selectedFiles = this.selectedFiles.concat(droppedFiles) // Add dropped files

    this.updateFileInput()
    this.renderFileList()
    this.toggleUploadButton()
  }

  renderFileList() {
    this.listTarget.innerHTML = ""

    this.selectedFiles.forEach((file, index) => {
      const div = document.createElement("div")
      div.textContent = file.name
      div.classList.add(
        "box",
        "box--bg-muted",
        "box--p-medium",
        "document-name",
        "d-flex",
        "align-items-center"
      )

      const removeButton = document.createElement("button")
      removeButton.textContent = "Remove"
      removeButton.classList.add(
        "button-component",
        "btn",
        "btn-danger",
        "ml-2"
      )
      removeButton.addEventListener("click", () => this.removeFile(index))

      div.appendChild(removeButton)
      this.listTarget.appendChild(div)
    })
  }

  removeFile(index) {
    this.selectedFiles.splice(index, 1)
    this.updateFileInput()
    this.renderFileList()
    this.toggleUploadButton()
  }

  toggleUploadButton() {
    if (this.selectedFiles.length > 0) {
      this.uploadButtonTarget.style.display = "block"
    } else {
      this.uploadButtonTarget.style.display = "none"
    }
  }

  updateFileInput() {
    const dataTransfer = new DataTransfer()

    this.selectedFiles.forEach((file) => dataTransfer.items.add(file))
    this.inputTarget.files = dataTransfer.files
  }
}
